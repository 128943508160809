// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import map from 'lodash/map'
import split from 'lodash/split'
import replace from 'lodash/replace'
import trim from 'lodash/trim'
import orderBy from 'lodash/orderBy'
// import slice from 'lodash/slice'
import reverse from 'lodash/reverse'
import filter from 'lodash/filter'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Tabs from 'antd/lib/tabs'
import 'antd/lib/tabs/style/css'

import ReactPlayer from 'react-player'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const pageSchema = {
  title: 'Aura Talks',
  slug: '/aura-talks',
  abstract:
    'The Aura Network brings the ideals of Auroville as a human laboratory of transformation into a digital network that can actually realize its vision of human unity.',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Aura Talks', slug: '/aura-talks' },
  ],
}

const { TabPane } = Tabs

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query AuraTalksPageQuery {
    allAuraTalksJson {
      nodes {
        title
        routeSlug
        remoteCover
        contentBlocks {
          content
          type
        }
        publishedTimestamp
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => {
  const {
    allAuraTalksJson: { nodes },
  } = props.data

  const ordered = reverse(orderBy(nodes, ['publishedTimestamp', 'asc']))
  const now = Math.floor(new Date().getTime() / 1000)

  return (
    <StandardPageWrapper
      className="aura-talks"
      pageSchema={pageSchema}
      {...props}
    >
      <Tabs defaultActiveKey="k0" tabPosition="right">
        {map(ordered, (node, index) => {
          const { publishedTimestamp, title, contentBlocks } = node
          let [seasonEpisode, subTitle] = split(title, ':')
          seasonEpisode = trim(replace(seasonEpisode, 'Aura Talks', ''))
          subTitle = trim(subTitle)
          const dateTime = new Date(
            publishedTimestamp * 1000
          ).toLocaleDateString('en-US')
          const [videoBlock] = filter(contentBlocks, { type: 'video' })

          return (
            <TabPane
              animated={false}
              key={`k${index}`}
              tab={
                <p
                  style={{
                    maxWidth: 'unset',
                    marginBottom: 0,
                    textAlign: 'left',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {subTitle}
                  <small
                    style={{
                      fontSize: '80%',
                      display: 'block',
                      marginTop: '0.15rem',
                    }}
                  >
                    {seasonEpisode}, {dateTime}
                  </small>
                </p>
              }
            >
              <Fragment>
                <div>
                  <div
                    className="homevideo as-paragraph"
                    style={{ maxWidth: 'unset' }}
                  >
                    <ReactPlayer
                      width="40rem"
                      height="25rem"
                      url={videoBlock.content}
                      controls
                      playing={false}
                    />
                  </div>
                  <h1>{subTitle}</h1>
                  {map(contentBlocks, ({ type, content }) => {
                    let returnThis = <Fragment />

                    if (type === 'text') {
                      returnThis = <p>{content}</p>
                    }

                    return returnThis
                  })}
                  <aside>
                    <p>
                      Our weekly podcast, <strong>Aura Talks</strong>, explore
                      the potentialities of Circular Basic Income, discuss our
                      ongoing efforts, and invite a diverse range of community
                      members regarding their experiences on the Aura Network.
                    </p>
                  </aside>
                </div>
              </Fragment>
            </TabPane>
          )
        })}
      </Tabs>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
